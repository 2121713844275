@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: 'Futura', sans-serif;
  }
}

body{
  background: #F5FBEF;
  color: #503D42;
}


